import React, {useState} from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from "@material-ui/core/Paper"
import FormControl from "@material-ui/core/FormControl"
import {TextField} from "@material-ui/core"
import Stepper from "@material-ui/core/Stepper"
import StepLabel from "@material-ui/core/StepLabel"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import StepContent from "@material-ui/core/StepContent"
import Step from "@material-ui/core/Step"
import {ReconBuddyAPI} from "../../ReconBuddyAPI"
import {ROUTES} from "../Routes"
import Snackbar from "@material-ui/core/Snackbar"
import MuiAlert from "@material-ui/lab/Alert/Alert"
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import FileSaver from 'file-saver'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    paper: {
      margin: '32px',
      padding: '32px',
    },
    icon: {
      borderRadius: '50%',
      width: 16,
      height: 16,
      boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
      backgroundColor: '#f5f8fa',
      backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
      '$root.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
      },
      'input:hover ~ &': {
        backgroundColor: '#ebf1f5',
      },
      'input:disabled ~ &': {
        boxShadow: 'none',
        background: 'rgba(206,217,224,.5)',
      },
    },
    checkedIcon: {
      backgroundColor: '#137cbd',
      backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
      '&:before': {
        display: 'block',
        width: 16,
        height: 16,
        backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
        content: '""',
      },
      'input:hover ~ &': {
        backgroundColor: '#106ba3',
      },
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    actionsContainer: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    resetContainer: {
      padding: theme.spacing(3),
    },
  }),
);

export default function OnboardingScreen(props: any) {
  const classes = useStyles();
  const [url, setUrl] = useState('YOUR DOMAIN');
  const handleApiUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUrl(event.target.value);
  };

  const [snackbarStatus, setSnackbarStatus] = React.useState({open: false, text: "N/A"});
  const handleSnackbarOpen = (text: string) => {
    setSnackbarStatus({open: true, text});
  };
  const handleSnackbarClose = (event: any, reason: any) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarStatus({open: false, text: snackbarStatus.text});
  };

  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();
  const handleNext = () => {
    const step = steps[activeStep]
    let fqdn = url
    if (!url.startsWith("http") && url !== "") {
      fqdn = "https://" + fqdn
    }
    if (step.type === "DOMAIN") {
      ReconBuddyAPI.updateProjectField(props.user, props.api.id, fqdn, "")
    } else if (step.type === "VERIFY") {
      console.log(props.api)
      handleSnackbarOpen("Verifying...")
      ReconBuddyAPI.verifyDomain(props.user, props.api.id, fqdn).then((resp) => {
        if (resp.status === 200) {
          handleSnackbarOpen("Verification successful!")
          setActiveStep(prevActiveStep => prevActiveStep + 1);
          window.location.href = ROUTES.DASHBOARD
        } else if (resp.status === 400) {
          handleSnackbarOpen("Couldn't verify domain")
        } else {
          // internal server errr
          handleSnackbarOpen("Internal server error:")
          // display message
        }
      })
      return;
    }

    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };
  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.keyCode === 13) {
      console.log(event.keyCode)
    }
  }

  function getSteps() {
    return [
      {
        title: "Your Website",
        desc: `Welcome to ReconBuddy! What website would you like to scan? An Acceptance/Development environment is recommended`,
        type: "DOMAIN",
        element: (
          <FormControl required={true} component="fieldset">
            <TextField
              autoFocus={true}
              required
              placeholder="https://app.example.com"
              id="filled-name"
              label="Your Website"
              margin="normal"
              variant="filled"
              onChange={handleApiUrlChange}
            />
          </FormControl>
        )
      },
      {
        title: "Is this your domain (2)?",
        desc: `Download the file below and place it in the root of your website.\nWe will try to access the file at ${url}/${props.api.verificationKey}`,
        type: "VERIFY",
        element: (
          <div>
            <Button
              variant="contained"
              color={"secondary"}
              startIcon={<CloudDownloadIcon />}
              onClick={() => {

                var blob = new Blob([props.api.verificationKey], {type: "text/plain;charset=utf-8"});
                FileSaver.saveAs(blob, `${props.api.verificationKey}.txt`);
              }}
            >
              Download Verification File
            </Button>
          </div>
        )
      },
      {
        title: "Is this your domain?",
        desc: 'Add TXT record using DNS. Copy the text below and add as a TXT record. This can take up to 48 hours depending on your DNS provider.',
        type: "VERIFY",
        element: (
          <div>
            <Button color={"secondary"} variant={"contained"}
                    style={{textTransform: 'none'}}
                    onClick={async () => {
                      const verificationKey = props.api.verificationKey
                      if (!navigator.clipboard) {
                        return;
                      }

                      try {
                        await navigator.clipboard.writeText(verificationKey);
                        handleSnackbarOpen("Verification token copied.")
                      } catch (error) {
                        console.error("Copy failed", error);
                      }
                    }}
            >
              {props.api.verificationKey}
            </Button>
          </div>
        )
      },
    ]
  }

  return (
    <Container component="main" maxWidth="md">
      <h1 style={{fontFamily: "Oswald"}}>ReconBuddy Onboarding</h1>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.title} onKeyDown={handleKeyDown}>
            <StepLabel>{step.title}</StepLabel>
            <StepContent>
              <Typography>{step.desc}</Typography>
              {step.element}
              <div className={classes.actionsContainer}>
                <div>
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                  >
                    Back
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                  >
                    {activeStep === steps.length - 1 ? 'Finish' : activeStep === 1 ? 'Verify' : 'Continue'}
                  </Button>
                </div>
              </div>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Paper square elevation={0} className={classes.resetContainer}>
          <Typography>All steps completed - you&apos;re finished</Typography>
          <Button
            onClick={() => setActiveStep(activeStep - 1)}
          >
            Back
          </Button>
        </Paper>
      )}
      <Snackbar open={snackbarStatus.open} autoHideDuration={10000} onClose={handleSnackbarClose}>
        <MuiAlert elevation={6} variant="filled" severity="info">
          {snackbarStatus.text}
        </MuiAlert>
      </Snackbar>
    </Container>
  );
}