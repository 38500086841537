import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {StyledFirebaseAuth} from "react-firebaseui"
import * as firebase from "firebase/app"
import ReconBuddyLogo from "../../assets/reconbuddyFullLogo.png"


const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxShadow: '0 12px 18px 2px rgba(34,0,51,.04), 0 6px 22px 4px rgba(7,48,114,.12), 0 6px 10px -4px rgba(14,13,26,.12)',
    padding: '32px',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  subHeader: {
    fontSize: '13px',
    color: '#888888',
    fontWeight: 400,
    textAlign: 'center',
    margin: '8px',
  },
  logo: {
    maxWidth: '200px',
    display: 'block',
    margin: 'auto'
  }
}));

export default function LoginPage() {
  const classes = useStyles();

  const uiConfig: firebaseui.auth.Config = {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'popup',
    // We will display Google and Facebook as auth providers.
    signInOptions: [
      {
        provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        scopes: [
          'profile',
        ],
      },
      {
        provider: firebase.auth.GithubAuthProvider.PROVIDER_ID,
      },
      {
        provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
        scopes: [
          'profile',
        ],
      },
    ],
    callbacks: {
      // Avoid redirects after sign-in.
      signInSuccessWithAuthResult: (authResult: any, redirect: any) => {
        window.location.href = '/dashboard'
        return true
      }
    }
  };


  return (
    <Container component="main" maxWidth="xs">
      <img className={classes.logo} src={ReconBuddyLogo} alt="ReconBuddy Stealth Plane" />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon/>
        </Avatar>
        <Typography component="h1" variant="h5">
          Welcome back!
        </Typography>
        <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()}/>
      </div>
    </Container>
  );
}