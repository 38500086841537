import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/analytics";
import RouteWrapper from "./Components/Pages/RouteWrapper"


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
if (firebaseConfig.authDomain?.includes("dev")) {
  console.log(firebaseConfig)
}
firebase.analytics()
ReactDOM.render(<RouteWrapper/>, document.getElementById('root'));


// DO NOT DO LIST

// Get lost in UI design and learning additional tech that isn't solving the problem
// 100% Automation (not necessary - can be partly human automated)
// reimplement security rules for firebase admin sdk
// redux

// END DO NOT DO LIST

// add firebase rules
// determine authorization relationships - https://firebase.google.com/docs/firestore/solutions/role-based-access

// how to share user data across components..
// db integrations should go throw front DB layer?
// fix warnings

// improve typescript compilation speed..
// add basic integration tests
// add project if it's they first time signing in?
// not receiving an error when saving to firebase
// setup firebase simulator and it's respective tests
// protected routes : https://medium.com/@nil4u.you/create-auth-guard-in-react-js-212df08ade47
// what is a service?
// pick typography
// stepper content is deleted after going back AND when switch routes..
// add auto-focusing
// notification badge for finished reports and count of vulnerabilities found
// cors preventing some swagger urls from working - SwaggerClient.http.withCredentials = true; // this activates CORS, if necessary
// help us create your swagger spec
// API Application to test against
// passing in a bad url to swagger-client fails poorly... can't use finally from promise or catch
// randomize id appended to base_scan.json
// functional onboarding (as little config as possible)
// "full" openapi scan
// automapping endpoints openapi
// autosignin (with openapi integration?)

// --- DONE ---
// add routing
// add typescript + upgrade to 3.7
// add basic firestore usage
// use firebase across multiple components without having to reinitialize
// setup git
// add signup & signin (firebase
// redirect to login if not logged in
// then redirect to link they wanted to go to
// automatic rerun server
// setup first typescript module
// deploy to firebase hosting app, link domain to hosting app and enable authentication for domain
// Use react hooks
// material UI setup
// color palette
// Add React Bootstrap or material UI
// onboarding flow
// add drawer
// bring back nav profile
// updating state after a function call. Use promises?
// endpoint scan report table & ui problems
// vulnerabilities dialog
// use optionals
// save reports
// don't render dashboard unless is has user and api object. Use more like diplay componenet
// start real "basic" api scan job
// display real data
// display all bad endpoints for each vulnerability
// firebase rules v1


// --- Automatic Create React App Maintenance
// npm install react-scripts@latest


