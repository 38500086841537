import './Pages/App.css';

export enum ROUTES {
  HOME = "/",
  DASHBOARD = "/dashboard",
  LOGIN = "/login",
  SIGNUP = "/signup",
  REGISTER = "/register",
  ONBOARDING = "/onboarding",
  // ONBOARDING_DOMAIN = "/onboarding/domain", // use grow transition
  // ONBOARDING_API = "/onboarding/api",
  // ONBOARDING_TEST_SUITE = "/onboarding/test-suite", // use transfer list
  // ONBOARDING_SCHEDULE = "/onboarding/schedule",
}

