import React from 'react';
import './App.css';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {ThemeProvider} from '@material-ui/styles';
import createMuiTheme from "@material-ui/core/styles/createMuiTheme"
import {BrowserRouter} from "react-router-dom";
import App from "./App"


const theme = createMuiTheme({
  palette: {
    primary: {main: '#211717'}, // Purple and green play nicely together.
    secondary: {main: '#f58b54'},
  },
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
  }),
);

export default function RouteWrapper(props: any) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <App/>
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
}
