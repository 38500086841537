import * as firebase from "firebase/app";
import "firebase/auth";
import React from 'react';
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import {Link} from "react-router-dom"
import {Avatar, makeStyles} from "@material-ui/core"
import Grid from "@material-ui/core/Grid"
import {ROUTES} from "./Routes"
import Typography from "@material-ui/core/Typography"

const useStyles = makeStyles({
  avatar: {
    margin: 10,
  },
  bigAvatar: {
    margin: 10,
    width: 40,
    height: 40,
  },
  pointerable: {
    cursor: 'pointer',
  },
  menuButton: {
    marginRight: 36
  }
});

export default function UserProfileUI(props: any) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSignOut = () => {
    firebase.auth().signOut();
    setAnchorEl(null);
  };

  return (
    <div className={classes.menuButton}>

      <div onClick={handleMenu}>
        {<Grid className={classes.pointerable} container justify="center" alignItems="center">
          {<Avatar src={props.user.photoUrl} className={classes.bigAvatar}/> && props.user.photoUrl}
          <Typography variant="h6" noWrap>
            {props.user.displayName}
          </Typography>
        </Grid> && props.user.displayName}
      </div>

      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem>Settings</MenuItem>
        <MenuItem onClick={handleSignOut} component={Link} to={ROUTES.LOGIN}>Sign out</MenuItem>
      </Menu>
    </div>
  )
}