import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import firestore from "firebase"

const ravenBaseUrl = process.env.REACT_APP_RAVEN_BASE_URL;

export class ReconBuddyAPI {
  static getWebApiOrCreateIfNotExists(user: firebase.User, name: string, url: string): Promise<any> {
    return fetch(`${ravenBaseUrl}/api/projects`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: `userUid=${user.uid}&name=${name}&url=${url}`,
    }).then((response: Response) => {
      return response.json();
    })
  }

  static updateProjectField(user: firebase.User, projectId: string, domain: string, gitUrl: string) {
    if (user == null) {
      return new Promise(() => {
      })
    }
    const db = firebase.firestore();
    if (domain) {
      db.collection("projects").doc(projectId).update("domain", domain)
      .catch(function (error) {
        console.error("Error getting report: ", error);
      })
    }
    if (gitUrl) {
      db.collection("projects").doc(projectId).update("gitUrl",gitUrl)
          .catch(function (error) {
            console.error("Error getting report: ", error);
          })
    }
  }

  static getReports(user: firebase.User, projectId: string) {
    if (user == null) {
      return new Promise(() => {
      })
    }
    const db = firebase.firestore();
    return db.collection("projects").doc(projectId).collection("reports").orderBy("Timestamp", "desc")
    .get()
    .then((querySnapshot) => {
      const reports: any = []
      querySnapshot.forEach((docRef) => {
        const data = docRef?.data();
        reports.push(data)
      })
      return reports
    })
    .catch(function (error) {
      console.error("ReconBuddyAPI firebase error getting report: ", error);
    }).finally(() => {
      console.log('report finally...')
    });
  }

  static onOneReport(user: firebase.User, projectId: string, callback: any) {
    if (user == null) {
      return new Promise(() => {
      })
    }
    const db = firebase.firestore();
    let ignoredFirstEvent = false;
    db.collection("projects").doc(projectId).collection("reports")
    .onSnapshot((col) => {
      // console.log("Current data: ", col);
      if (ignoredFirstEvent)  {
        callback(col)
      }
      ignoredFirstEvent = true
    });
  }

  static createBaseScan(user: firestore.User, projectId: string, domain: string, gitUrl: string): Promise<Response> {
    return user.getIdToken().then(idToken => {
      return fetch(`${ravenBaseUrl}/api/projects/${projectId}/scan/zap-base`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authentication': `Bearer ${idToken}`,
        },
        body: `domain=${domain}&gitUrl=${gitUrl}`,
      })
    });
  }
  static createBigKahunaScan(user: firestore.User, projectId: string, domain: string, gitUrl: string): Promise<Response> {
    return user.getIdToken().then(idToken => {
      return fetch(`${ravenBaseUrl}/api/projects/${projectId}/scan/big-kahuna`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authentication': `Bearer ${idToken}`,
        },
        body: `domain=${domain}&gitUrl=${gitUrl}`,
      })
    });
  }

  static getOrCreatePlan(user: firestore.User): Promise<Response> {
    return user.getIdToken().then(idToken => {
      return fetch(`${ravenBaseUrl}/api/plan`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authentication': `Bearer ${idToken}`,
        },
      }).then((response: Response) => {
        return response.json()
      })
    });
  }

  static goToStripeCustomerPortal(user: firestore.User): Promise<Object> {
    return user.getIdToken().then(idToken => {
      return fetch(`${ravenBaseUrl}/create_customer_portal_session`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authentication': `Bearer ${idToken}`,
        },
        body: ``,
      }).then(resp => {
        return resp.json()
      }).then(resp => {
        window.location.href = resp.url
        return ""
      })
    });
  }


  static requestFullScan(user: firestore.User, projectId: string, domain: string, username: string, password: string, loginPage: string): Promise<any> {
    if (user == null) {
      return new Promise(() => {
      })
    }
    const db = firebase.firestore();
    return db.collection("projects").doc(projectId).update("fullScan", {
      status: "REQUESTED",
      authType: "UNKNOWN",
      username,
      password,
      loginPage
    })
    .catch(function (error) {
      console.error("Error setting full scan: ", error);
    })
  }

  static createFullScan(projectId: string, url: string): Promise<Response> {
    return fetch(`${ravenBaseUrl}/api/projects/${projectId}/scan/zap-full`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: `url=${url}`,
    })
  }

  static createApiScan(projectId: string, url: string, specUrl: string): Promise<Response> {
    return fetch(`${ravenBaseUrl}/api/projects/${projectId}/scan/zap-api`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: `url=${url}&specUrl=${specUrl}`,
    })
  }

  static verifyDomain(user: firebase.User, projectId: string, domain: string, ) {
    return fetch(`${ravenBaseUrl}/api/verify`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: `projectId=${projectId}&domain=${domain}`,
    })
  }


}