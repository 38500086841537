import React, {useEffect, useState} from 'react';
import './App.css';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import PersonIcon from '@material-ui/icons/Person';
import HomeIcon from '@material-ui/icons/Home';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import MenuIcon from '@material-ui/icons/Menu';
import DeveloperModeIcon from '@material-ui/icons/DeveloperMode';
import {ROUTES} from "../Routes"
import createMuiTheme from "@material-ui/core/styles/createMuiTheme"
import {Drawer} from "@material-ui/core"
import clsx from 'clsx'
import Divider from "@material-ui/core/Divider"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import NotificationsIcon from '@material-ui/icons/Notifications';
import CssBaseline from "@material-ui/core/CssBaseline"
import LoginPage from "./LoginPage"
import OnboardingScreen from "./OnboardingPage"
import Dashboard from "./DashboardPage"
import {Link, Route, Switch, useHistory} from "react-router-dom";
import UserProfileUI from "../UserProfileUI";
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/analytics';
import {ReconBuddyAPI} from "../../ReconBuddyAPI";
import firestore from "firebase"
import {Api} from "../../propTypes/propTypes"
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress"


const theme = createMuiTheme({
  palette: {
    primary: {main: '#211717'}, // Purple and green play nicely together.
    secondary: {main: '#f58b54'},
  },
});

const drawerWidth = 240;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 36,
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9) + 1,
      },
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
  }),
);

export default function App(props: any) {
  const classes = useStyles();
  // undefined == checking if logged in, null = logged out, value = logged in
  const [currentUser, setCurrentUser] = useState<firestore.User | undefined | null>(undefined)
  const history = useHistory();
  const [api, setApi] = useState<Api | undefined | null>(undefined)//useState(FakeData.getWebApiValidWithFirebaseSwaggerAndCorsNecessary)
  const [plan, setPlan] = useState({nickname: ""})

  // authentication effect
  useEffect(() => {
    const unregisterAuthObserver = firebase.auth().onAuthStateChanged(
      (user) => {
        if (user) {
          firebase.analytics().setUserId(user.uid)
          // @ts-ignore
          setCurrentUser(user);
          user.updateProfile({
            displayName: user.displayName || user.email,
            photoURL: user.photoURL,
          }).then(() => {
            console.log('update successful')
          }).catch(error => {
            console.error(error)
          });
          ReconBuddyAPI.getWebApiOrCreateIfNotExists(user, "", "").then((webApi: any) => {
            // console.log(webApi)
            if (webApi) {
              const projectId = webApi.id
              ReconBuddyAPI.getReports(user, projectId).then((reports: any) => {
                webApi.reports = reports
                setApi(webApi);
              })
            } else {
              setApi(null)
            }
            if (webApi.isOnboarded) {
              history.push(ROUTES.DASHBOARD)
            } else {
              history.push(ROUTES.DASHBOARD)
            }
          }).catch((resp) => {
            setApi(null)
          })
          ReconBuddyAPI.getOrCreatePlan(user).then((plan: any) => {
            if (plan) {
              console.log(plan)
              setPlan(plan)
            }
          })
        } else {
          setCurrentUser(null)
        }
      }
    )
    return () => {
      unregisterAuthObserver();
    };
  }, []);

  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  return (
    <div className={classes.root} style={{margin: "auto"}}>
      {currentUser === undefined ?
        <div>
          <div style={{marginTop: "24px"}}>Initializing auth...</div>
          <p/>
          <CircularProgress size={64}/>
        </div> :
        currentUser === null ? <div style={{marginTop: "64px"}}><LoginPage/></div> :
          currentUser?.displayName !== "" && api ?
            <div className={classes.root}>
              <div className={classes.root}>
                <CssBaseline/>
                <AppBar
                  position="fixed"
                  className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                  })}
                >
                  <Toolbar>
                    <IconButton
                      color="inherit"
                      aria-label="open drawer"
                      onClick={handleDrawerOpen}
                      edge="start"
                      className={clsx(classes.menuButton, {
                        [classes.hide]: open,
                      })}
                    >
                      <MenuIcon/>
                    </IconButton>
                    <UserProfileUI user={currentUser}/>
                    <NotificationsIcon/>
                    <Button  variant="contained"
                                 color={plan.nickname === "basic" ? "secondary" : "primary"} onClick={() => {
                      ReconBuddyAPI.goToStripeCustomerPortal(currentUser)
                    }}>{plan.nickname === "basic" ? "upgrade" : plan.nickname}</Button>
                  </Toolbar>
                </AppBar>
                <Drawer
                  variant="permanent"
                  className={clsx(classes.drawer, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                  })}
                  classes={{
                    paper: clsx({
                      [classes.drawerOpen]: open,
                      [classes.drawerClose]: !open,
                    }),
                  }}
                  open={open}
                >
                  <div className={classes.toolbar}>
                    <IconButton onClick={handleDrawerClose}>
                      {theme.direction === 'rtl' ? <ChevronRightIcon/> : <ChevronLeftIcon/>}
                    </IconButton>
                  </div>
                  <Divider/>
                  <List>
                    {

                      [
                        {text: 'Dashboard', route: ROUTES.DASHBOARD, icon: <HomeIcon/>},
                        {text: 'Sign In', route: ROUTES.LOGIN, dev: true, icon: <PersonIcon/>},
                        {text: 'Onboarding', route: ROUTES.ONBOARDING, dev: true, icon: <DeveloperModeIcon/>},
                      ]
                      .map((drawerButtonContent, index) => {
                        if (drawerButtonContent.dev && process.env.NODE_ENV === 'production') {
                          return null;
                        }
                        return <ListItem button key={drawerButtonContent.text} component={Link}
                                  to={drawerButtonContent.route}>
                          <ListItemIcon>{drawerButtonContent.icon}</ListItemIcon>
                          <ListItemText primary={drawerButtonContent.text}/>
                        </ListItem>
                    })}
                  </List>
                  <Divider/>
                </Drawer>
              </div>
              <main className={classes.content}>
                <div className={classes.toolbar}/>
                <Switch>

                  <Route path={ROUTES.ONBOARDING}>
                    <OnboardingScreen user={currentUser} api={api}/>
                  </Route>
                  <Route path={ROUTES.SIGNUP} component={LoginPage}/>
                  <Route path={ROUTES.LOGIN} component={LoginPage}/>
                  <Route path={ROUTES.HOME}>
                    <Dashboard user={currentUser} api={api}/>
                  </Route>
                  <Route path={ROUTES.DASHBOARD}>
                    <Dashboard user={currentUser} api={api}/>
                  </Route>
                </Switch>
              </main>
            </div> : api === undefined ?
            <div style={{marginTop: "24px"}}>ReconBuddy data arriving...</div> : api === null ?
              <div style={{marginTop: "24px"}}>There was an error connecting to ReconBuddy's servers. Please try again later.</div> : <OnboardingScreen user={currentUser}/>
      }
    </div>
  );
}

/*
// currentUser.uid === "N/A" - loading
// currentUser.uid === "" - not signed in
// currentUser.uid !== "" - signed in
// api - signed in data loaded
*/
