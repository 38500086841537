import "firebase/auth";
import React, {useEffect} from 'react';
import MaterialTable from "material-table"
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser"
import ErrorIcon from "@material-ui/icons/Error"
import {makeStyles, TextField} from "@material-ui/core"
import Paper from "@material-ui/core/Paper"
import Button from "@material-ui/core/Button"
import {ReconBuddyAPI} from "../../ReconBuddyAPI"
import CircularProgress from "@material-ui/core/CircularProgress"
import {green, red} from "@material-ui/core/colors"
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import Snackbar from "@material-ui/core/Snackbar"
import MuiAlert from '@material-ui/lab/Alert';
import {ROUTES} from "../Routes"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogContent from "@material-ui/core/DialogContent"
import DialogActions from "@material-ui/core/DialogActions"
import firestore from "firebase"
import {Api} from "../../propTypes/propTypes"
import Tooltip from "@material-ui/core/Tooltip"
import moment from "moment";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import ReactJson from "react-json-view";
import firebase from 'firebase/app';
import 'firebase/analytics';

const useStyles = makeStyles(theme => ({
  paper: {
    margin: theme.spacing(4),
    boxShadow: '0 12px 18px 2px rgba(34,0,51,.04), 0 6px 22px 4px rgba(7,48,114,.12), 0 6px 10px -4px rgba(14,13,26,.12)',
    padding: '32px',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    margin: theme.spacing(4, 0, 2),
  },
  vulnerabilityHeader: {
    backgroundColor: theme.palette.background.paper,
  },
  displayNone: {
    display: "none"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

interface DashboardProps {
  user: firestore.User;
  api: Api;
}

export default function Dashboard(props: DashboardProps) {
  const classes = useStyles()
  const [state, setState] = React.useState({
    url: props.api.domain,
    gitUrl: props.api.gitUrl,
    isBaseScanLoading: false,
    isBigScanLoading: false,
    selectedReport: props.api.reports[0],
    openReportSelector: false,
    fullScanDialogOpen: false,
    username: '',
    password: '',
    loginPage: '',
    termsOfService: true
  });

  useEffect(() => {
    if (state.isBaseScanLoading || state.isBigScanLoading) {
      document.title = "Scanning..."
    } else {
      document.title = "ReconBuddy"
    }
  }, [state.isBaseScanLoading, state.isBigScanLoading]);

  const materialTableConfig = () => {
    let combinedAlerts: any = []
    for (let i = 0; i < state.selectedReport?.Site?.length; i++) {
      const site = state.selectedReport?.Site[i];
      combinedAlerts = combinedAlerts.concat(site.Alerts)
    }
    return {
      "zap-base": {
        data: combinedAlerts,
        columns: [{title: "Alert", field: "Alert"}, {title: "Risk & Confidence", field: "Riskdesc"},
          {title: "Endpoints", field: "Instances.length"},
          {
            title: "Status", field: "Instances",
            // @ts-ignore
            render: rowData => true ? <ErrorIcon htmlColor={red[900]}/> :
              <VerifiedUserIcon htmlColor={green[500]}/>
          },
          {
            title: "Vulnerability", field: "Desc",
            // @ts-ignore
            render: rowData => <div
              style={{minWidth: "400px"}}>{rowData.Desc.replace("<p>", "").replace("</p>", "")}</div>
          }],
        detailPanel: [
          // @ts-ignore
          rowData => ({
            icon: () => {
              // @ts-ignore
              return (rowData.Instances.length === 0) ? null : <ChevronRightIcon/>
            },
            openIcon: KeyboardArrowDownIcon,
            tooltip: 'View Vulnerabilities',
            render: (endpoint: any) => {
              if (!endpoint.Instances || endpoint.Instances.length === 0) {
                return null
              }
              return (
                <MaterialTable
                  columns={[
                    {title: "Method", field: "Method"},
                    {title: "Path", field: "URI"},
                    {title: "Param", field: "Param"},
                  ]}
                  options={{
                    headerStyle: {
                      backgroundColor: '#575757',
                      color: '#FFF'
                    },
                    rowStyle: {
                      backgroundColor: '#ededed',
                    },
                    showTitle: false,
                    hideFilterIcons: true,
                    showFirstLastPageButtons: false,
                    showSelectAllCheckbox: false,
                    showEmptyDataSourceMessage: true,
                    showTextRowsSelected: false,
                    search: false,
                    sorting: false,
                    toolbar: false,
                    paging: false,
                  }}
                  data={endpoint.Instances}
                  title={"View Vulnerable Endpoints"}
                />
              )
            },
          }),
        ]
      },
      wappalyzer: {
        data: state.selectedReport?.technologies,
        columns: [
          {
            title: "Avatar",
            field: "icon",
            render: (rowData: { icon: string }) => <img src={"https://www.wappalyzer.com/images/icons/" + rowData.icon}
                                                        style={{width: 25, borderRadius: '50%'}} alt={"wappalyzer icon"}/>
          },
          {title: "Technology", field: "name"},
          {
            title: "Website",
            field: "website",
            render: (rowData: { website: string }) => <a target={"_blank"} rel="noopener noreferrer"  href={rowData.website}>{rowData.website}</a>
          },
        ]
      },
      tsunami: {
        data: state.selectedReport?.fullDetectionReports?.detectionReports,
        columns: [{title: "Name", field: "vulnerability.title"},
          {title: "Description", field: "vulnerability.description"},
          {title: "Severity", field: "vulnerability.severity"},
          {title: "Address", field: "networkService.networkEndpoint.ipAddress.address"},
          {title: "Port", field: "networkService.networkEndpoint.port.portNumber"},
        ]
      },
      findomain: {
        data: state.selectedReport.Subdomains,
        columns: [
          {title: "Subdomain", field: "subdomain"},
          {title: "Verified Public URL", field: "isAccessible", render: (rowData: {isAccessible: boolean}) => rowData.isAccessible ? "YES" : ""},
          {
            title: "New Scan", render: (rowData: { subdomain: string }) => <Button variant="contained"
                                                                                   color="secondary"
                                                                                   disabled={state.isBaseScanLoading}
                                                                                   onClick={() => newSubdomainScan(rowData.subdomain)}>Basic Scan Subdomain</Button>
          },
        ]
      },
      gosec: {
        data: state.selectedReport?.Issues,
        columns: [{title: "Name", field: "details"},
          {title: "Risk", field: "severity"},
          {title: "Confidence", field: "confidence"},
          {title: "File", field: "file"},
          {title: "Code", field: "code"},
          {title: "line", field: "line"},
        ]
      },
      nancy: {
        data: state.selectedReport?.vulnerable,
        columns: [
          {title: "Coordinated", field: "Coordinates"},
          {title: "Reference", field: "Reference"},
          {title: "Vulnerabilities", field: "Vulnerabilities"},
        ]
      },
      semgrep: {
        data: state.selectedReport?.results,
        columns: [
          {title: "File", field: "path"},
          {title: "Line #", field: "end.line"},
          {title: "Code", render: (rowData: { extra: {lines: string } }) => <span>{rowData.extra.lines.substring(0, 700)}</span>},
          {title: "Description", field: "extra.message"},
          {title: "CWE", field: "extra.metadata.cwe"},
          {title: "ID", field: "check_id"},
        ]
      },
    }
  }

  const setField = (field: object) => {
    setState({
      ...state,
      ...field
    });
  }

  const newSubdomainScan = (subdomain: string) => {
    handleClickBaseScanNow(props.api.id, subdomain, state.gitUrl)
    setField({url: subdomain, isBaseScanLoading: true})
  };

  const handleApiUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setField({url: event.target.value})
  };

  const handleGitUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setField({gitUrl: event.target.value})
  };

  const handleChangeUsername = (event: React.ChangeEvent<HTMLInputElement>) => {
    setField({username: event.target.value})

  };
  const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setField({password: event.target.value})
  };
  const handleChangeLoginPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setField({loginPage: event.target.value})
  };

  const handleToggleTermsOfService = (event: React.ChangeEvent<HTMLInputElement>) => {
    setField({termsOfService: !state.termsOfService})
  };


  const handleChange = (event: React.ChangeEvent<{ value: any }>) => {
    /* if (event.target.value.Site[0].Alerts.length === 0) {
      event.target.value.Site[0].Alerts.push({
        Alert: 'No errors found!',
        Instances: [],
        Desc: ''
      })
    } */
    setField({
      openReportSelector: false,
      selectedReport: event.target.value
    })
  };
  const handleClose = () => {
    setField({openReportSelector: false})
  };
  const handleOpen = () => {
    firebase.analytics().logEvent("open_report_selector")
    setField({openReportSelector: true})
  };
  const handleFullScanDialogOpen = () => {
    setField({fullScanDialogOpen: true})
  };
  const handleFullScanDialogClose = () => {
    setField({fullScanDialogOpen: false})
  };
  const handleFullScanDialogRequest = () => {
    firebase.analytics().logEvent("request_full_scan")
    handleFullScanDialogClose();
    ReconBuddyAPI.requestFullScan(props.user, props.api.id, props.api.domain, state.username, state.password, state.loginPage)
    handleSnackbarOpen("Authenticated Full Scan requested! Check back within two days to see your results.")
  };

  const handleClickBaseScanNow = (projectId: string, domain: string, gitUrl: string) => {
    firebase.analytics().logEvent("base_scan")
    if (domain && !domain.startsWith("http")) {
      domain = "https://" + domain
    }
    if (gitUrl && !gitUrl.startsWith("http")) {
      domain = "https://" + domain
    }
    ReconBuddyAPI.updateProjectField(props.user, props.api.id, domain, gitUrl)
    ReconBuddyAPI.createBaseScan(props.user, projectId, domain, gitUrl).then((response: Response) => {
      response.json().then(function (body: any) {
        if (response.status === 200) {
          handleSnackbarOpen("Base Scan initiated! Refresh your page 10-15 minutes later. Report will show on this page.")
        } else {
          handleSnackbarOpen("An issue occurred please try again later: " + response.statusText)
          setField({isBigScanLoading: false})
        }
      });
    }).catch((error) => {
      handleSnackbarOpen("An issue occurred please try again later: " + error)
      setField({isBigScanLoading: false})
    })
    ReconBuddyAPI.onOneReport(props.user, projectId, (collection: any) => {
      window.location.href = ROUTES.DASHBOARD
      setField({isBaseScanLoading: false})
    })
    setField({isBaseScanLoading: true})
  };
  const handleClickBigKahunaNow = (projectId: string, domain: string, gitUrl: string) => {
    firebase.analytics().logEvent("big_scan")
    if (!domain.startsWith("http") && domain !== "") {
      domain = "https://" + domain
    }
    if (!gitUrl.startsWith("http") && gitUrl !== "") {
      domain = "https://" + domain
    }
    ReconBuddyAPI.updateProjectField(props.user, props.api.id, domain, gitUrl)
    ReconBuddyAPI.createBigKahunaScan(props.user, projectId, domain, gitUrl).then((response: Response) => {
      response.json().then(function (body: any) {
        if (response.status === 200) {
          handleSnackbarOpen("Big Kahuna Scan initiated! You will receive an email in ~10 minutes when your scan is completed. Reports will show on this page.")
        } else {
          handleSnackbarOpen("An issue occurred please try again later: " + response.statusText)
          setField({isBigScanLoading: false})
        }
      });
    }).catch((error) => {
      handleSnackbarOpen("An issue occurred please try again later: " + error)
      setField({isBigScanLoading: false})
    })
    ReconBuddyAPI.onOneReport(props.user, projectId, (collection: any) => {
      window.location.href = ROUTES.DASHBOARD
      setField({isBigScanLoading: false})
    })
    setField({isBigScanLoading: true})
  };

  const [snackbarStatus, setSnackbarStatus] = React.useState({open: false, text: "N/A"});

  const handleSnackbarOpen = (text: string) => {
    setSnackbarStatus({open: true, text});
  };

  const handleSnackbarClose = (event: any, reason: any) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarStatus({open: false, text: snackbarStatus.text});
  };

  const getDataViewer = () => {
    if (state.selectedReport.Type === "nmap") {
      return <ReactJson src={state.selectedReport.nmaprun} indentWidth={4} name={false} collapseStringsAfterLength={100}
                        displayDataTypes={false}/>
    } else {
      // @ts-ignore
      const dataSize = materialTableConfig()[state.selectedReport.Type]?.data?.length ?? 0
      return <MaterialTable
        key={dataSize}
        // @ts-ignore
        data={materialTableConfig()[state.selectedReport.Type]?.data ?? []}
        // @ts-ignore
        columns={materialTableConfig()[state.selectedReport.Type]?.columns}
        options={{
          headerStyle: {
            backgroundColor: '#170F0F',
            color: '#FFF'
          },
          rowStyle: {
            backgroundColor: '#faf7f7',
          },
          showTitle: false,
          hideFilterIcons: true,
          showFirstLastPageButtons: false,
          showSelectAllCheckbox: false,
          showEmptyDataSourceMessage: false,
          showTextRowsSelected: false,
          search: false,
          sorting: false,
          toolbar: false,
          paging: true,
          pageSize: Math.min(dataSize, 10),
        }}
        title={"ReconBuddy Scan - " + props.api.reports[0].Generated}
        //onRowClick={(event, rowData, togglePanel: any) => togglePanel()}
        // @ts-ignore
        detailPanel={materialTableConfig[state.selectedReport.Type]?.detailPanel}
      />
    }
  }

  return (
    <div style={{maxWidth: "100%"}}>
      <h1 style={{fontFamily: "Oswald"}}>ReconBuddy Dashboard</h1>
      {
        <Paper hidden={props.api.openApiSpecUrl !== "" && props.api.openApiSpecUrl !== null}
               className={classes.paper}>
          <h2>Required: Your API needs to be mapped</h2>
          <span>We need to know each of your endpoints and how to interact with each one of them</span>
        </Paper>
      }
      <div style={{display: 'flex'}}>
        <Paper className={classes.paper} style={{flexGrow: 1}}>
          <h2>New Scan</h2>
          <TextField
            style={{minWidth: "300px"}}
            id="api-url"
            label="Your Website"
            variant={"filled"}
            disabled={false}
            value={state.url}
            onChange={handleApiUrlChange}
          />
          <br/>
          <br/>
          <TextField
            style={{minWidth: "300px"}}
            id="git-url"
            label="Your Git Repo URL (BETA)"
            variant={"filled"}
            disabled={false}
            defaultValue={props.api.gitUrl}
            placeholder={"https://github.com/username/Project.git"}
            onChange={handleGitUrlChange}
          />
          <br/>
          <br/>
          <FormControlLabel
            control={<Checkbox size={"small"} checked={state.termsOfService} onChange={handleToggleTermsOfService}
                               name="checkedA"/>}
            label="I am authorized to scan this target website and I agree to the Terms of Service"
          />
          <div className={classes.wrapper}>
            <Tooltip
              title={"Basic Scan checks the fundamentals of your website. Use this to discover some common vulnerabilities in your website."}>
              <Button
                variant="contained"
                color="primary"
                disabled={state.isBaseScanLoading}
                onClick={() => {
                  handleClickBaseScanNow(props.api.id, state.url, state.gitUrl)
                }}
              >
                Run Basic Scan
              </Button>
            </Tooltip>
            {state.isBaseScanLoading && <CircularProgress size={24} className={classes.buttonProgress}/>}
          </div>
          <p/>
          <div className={classes.wrapper}>
            <Tooltip
              title={"Full Scan is an in-depth authenticated active scan. Use this to discover many classes of vulnerabilities in your website."}>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleFullScanDialogOpen}
              >
                {props.api.fullScan === undefined ? "Request Full Scan" : "Full Scan Requested"}
              </Button>
            </Tooltip>
          </div>
          <p/>
          <div className={classes.wrapper}>
            <Tooltip
              title={"The Big Scan does static code analysis, dependency checks, technology collection and pen testing. Each analysis is saved as its own report."}>
              <Button
                variant="contained"
                color="primary"
                disabled={state.isBigScanLoading}
                onClick={() => {
                  handleClickBigKahunaNow(props.api.id, state.url, state.gitUrl)
                }}
              >
                Run Big Scan
              </Button>
            </Tooltip>
            {state.isBigScanLoading && <CircularProgress size={24} className={classes.buttonProgress}/>}
          </div>
        </Paper>
        <Paper className={classes.paper} style={{flexGrow: 1, opacity: '20%'}}>
          <h2>Scan Scheduling</h2>
          {/*<TextField*/}
          {/*  label="Next Scheduled Scan"*/}
          {/*  type="datetime-local"*/}
          {/*  variant={"filled"}*/}
          {/*  disabled={true}*/}
          {/*  defaultValue={props.api.scanSettings?.nextScan?.format("YYYY-MM-DDThh:mm")}*/}
          {/*/>*/}
          <p></p>
          <TextField
            label="Scan Frequency"
            variant={"filled"}
            disabled={true}
            defaultValue={`Once ${props.api.frequency}`}
          />
          <p/>
          <TextField
            label="Next Scan"
            variant={"filled"}
            disabled={true}
            defaultValue={props.api.nextScan}
          />
        </Paper>
      </div>

      <div/>

      {state.selectedReport && <FormControl variant="filled" className={classes.formControl}>
        <InputLabel htmlFor="selected-frequency">
          Reports
        </InputLabel>
        <Select
          open={state.openReportSelector}
          onClose={handleClose}
          onOpen={handleOpen}
          value={state.selectedReport}
          onChange={handleChange}
          autoWidth={true}
          inputProps={{
            name: 'report',
            id: 'selected-report',
          }}
        >
          {props.api.reports.map((report: any, index: number) => {
            const date = moment.unix(report.Timestamp / 1000000000).format("DD MMM YYYY hh:mm a")
            return <MenuItem key={date + index + "report"}
                             value={report}>{report.Target} - {date} - {report.Type}</MenuItem>
          })}
        </Select>
      </FormControl>}

      {state.selectedReport && getDataViewer()}
      <Dialog open={state.fullScanDialogOpen} onClose={handleFullScanDialogClose}
              aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Request Full (Authenticated) Scan</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To execute an authenticated scan, please provide a test user account.
          </DialogContentText>
          <TextField
            autoFocus
            variant={"outlined"}
            margin="dense"
            label="Username"
            fullWidth
            onChange={handleChangeUsername}
          />
          <TextField
            margin="dense"
            variant={"outlined"}
            label="Password"
            fullWidth
            onChange={handleChangePassword}
          />
          <TextField
            margin="dense"
            variant={"outlined"}
            label="Login Page (Optional)"
            placeholder={"app.example.com/login"}
            fullWidth
            onChange={handleChangeLoginPage}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleFullScanDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleFullScanDialogRequest} color="primary">
            Request
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={snackbarStatus.open} autoHideDuration={10000} onClose={handleSnackbarClose}>
        <MuiAlert elevation={6} variant="filled" severity="info">
          {snackbarStatus.text}
        </MuiAlert>
      </Snackbar>
    </div>
  );
}

